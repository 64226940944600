import 'src/assets/css/Loader.css'
function Loader() {
	return (
		<div className="loader">
			<div className="inner one"></div>
			<div className="inner two"></div>
			<div className="inner three"></div>
		</div>
	);
}

export default Loader
